export default class UserReadDto {
  id: number;
  email: string;
  chosenName: string;
  dateOfBirth: string;
  gender: boolean;
  weightKilograms: number;
  heightCentimeters: number;
  profilePicture: string;

  constructor(id: number, email: string, chosenName: string, dateOfBirth: string, gender: boolean, weightKilograms: number, heightCentimeters: number, profilePicture: string) {
    this.id = id;
    this.email = email;
    this.chosenName = chosenName;
    this.dateOfBirth = dateOfBirth;
    this.gender = gender;
    this.weightKilograms = weightKilograms;
    this.heightCentimeters = heightCentimeters;
    this.profilePicture = profilePicture;
  }
}
