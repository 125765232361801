import axios from "axios";
import { useEffect, useRef, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Endpoint from "../system/Endpoint";
import Labels from "../system/Labels_sr_Latn_RS";
import { NAVBAR_MENU_ACTIVE } from "../system/Utils";
import { Style } from "./HeaderStyle";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import { AppContext } from "../system/Store";
import { getImage } from "../system/Utils";
import Images from "../system/Images";
import { confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { useMediaQuery } from "react-responsive";

export default function Header() {
  const [articleAreaList, setArticleAreaList] = useState([]);
  const history = useHistory();
  const menuButtonRef = useRef();
  const itemButtonRef = useRef();
  const [selectedButton, setSelectedButton] = useState();
  let location = useLocation();
  const { authData, deleteAuthData } = useContext(AppContext);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    var articleArray = [];
    axios
      .get(Endpoint.ARTICLE_AREA)
      .then((responseArticleArea) => {
        responseArticleArea.data.forEach((article) => {
          articleArray.push({ title: article.name, articleID: article.id, articleCode: article.code });
        });
        setArticleAreaList(articleArray);
      })
      .catch((error) => {});
    window.addEventListener("popstate", () => {
      if (window.location.pathname === "/") {
        setSelectedButton(undefined);
      }
    });
  }, []);

  function onItemButtonClick(event) {
    if (event.type === "click") {
      if (window.innerWidth < NAVBAR_MENU_ACTIVE) menuButtonRef.current.click();
    }
  }

  const menuHeaderUserModel = [
    {
      items: [
        {
          label: authData?.currentUser.chosenName,
          icon: "pi pi-user-edit",
          command: () => {
            history.push("/userProfile/" + authData?.currentUser.id);
          },
        },
        {
          label: Labels.BUTTON_LOGOUT,
          icon: "pi pi-power-off",
          command: (e) => {
            confirmDialog({
              header: Labels.BUTTON_LOGOUT,
              acceptLabel: Labels.YES,
              rejectLabel: Labels.NO,
              target: e.currentTarget,
              message: Labels.ARE_YOU_SURE_YOU_WANT_TO_PROCEED,
              icon: "pi pi-power-off",
              accept: () => onLogOut(),
            });
          },
        },
      ],
    },
  ];
  let menuHeaderUser = null;

  const onLogOut = () => {
    deleteAuthData();
    history.push("/login");
  };

  const loginTemplate = () => {
    history.push("/login");
  };

  useEffect(() => {
    articleAreaList.map((area) => {
      let path = location.pathname;
      if (path.substr(location.pathname.lastIndexOf("/") + 1) === area.articleID.toString()) {
        setSelectedButton(area.title);
      } else if (path.substr(location.pathname.lastIndexOf("/")) === "/savetovalista") {
        setSelectedButton(Labels.COUNSELING);
      }
      return true;
    });
  }, [location, articleAreaList]);

  return (
    <div>
      {!isTabletOrMobile && (
        <div className="header_top">
          <div className="row">
            <a href={Endpoint.CITIESCHANGINGDIABETES} target="_blank" rel="noreferrer">
              <img src={Images.GRADOVI_MENJAJU_DIJABETES} alt="partner" className="header_logo" />
            </a>
            <a href={Endpoint.TALKABOUTDIABETES} target="_blank" rel="noreferrer">
              <img src={Images.TALK_ABOUT_DIABETES} alt="partner" className="header_logo" />
            </a>
          </div>
          <div className="row">
            <h1 className="header_top_blue mt-2">{Labels.APPLICATION_NAME}</h1>
          </div>
          <div className="row">
            <a href="/">
              <img src={Images.LOGO_ON_WHITE} alt="logoOnWhite" style={Style.logo} />
            </a>
            {authData?.token && (
              <div className=" user-profile-avatar">
                <Menu ref={(el) => (menuHeaderUser = el)} model={menuHeaderUserModel} popup={true} />
                <div
                  className="align-center"
                  onClick={(event) => {
                    menuHeaderUser.toggle(event);
                  }}
                >
                  <Avatar image={getImage(false, authData?.currentUser.profilePicture, Images.NO_PROFILE)} shape="circle" size="large" style={Style.avatarStyleUser} />
                </div>
              </div>
            )}
            {!authData?.token && (
              <Button onClick={loginTemplate} tooltip="Login" style={Style.loginIcon}>
                <Avatar image={getImage(false, "", Images.LOGIN_ICON)} shape="circle" size="large" style={Style.avatarStyle} />
              </Button>
            )}
          </div>
        </div>
      )}
      {isTabletOrMobile && (
        <div className="p-grid p-justify-between" style={Style.gridStyle}>
          <div className="p-col-3">
            <a href={Endpoint.CITIESCHANGINGDIABETES} target="_blank" rel="noreferrer">
              <img src={Images.GRADOVI_MENJAJU_DIJABETES} alt="partner" className="header_logo" />
            </a>
            <a href={Endpoint.TALKABOUTDIABETES} target="_blank" rel="noreferrer">
              <img src={Images.TALK_ABOUT_DIABETES} alt="partner" className="header_logo" />
            </a>
          </div>
          <div className="p-col-7" style={Style.centerAlign}>
            <a href="/">
              <img src={Images.LOGO_ON_WHITE} alt="logoOnWhite" style={Style.logoOnMobile} />
            </a>
          </div>
          <div className="p-col-2" style={Style.rightAlign}>
            {authData?.token && (
              <div>
                <Menu ref={(el) => (menuHeaderUser = el)} model={menuHeaderUserModel} popup={true} />
                <div
                  onClick={(event) => {
                    menuHeaderUser.toggle(event);
                  }}
                >
                  <Avatar image={getImage(false, authData?.currentUser.profilePicture, Images.NO_PROFILE)} shape="circle" size="large" style={Style.avatarStyleUser} />
                </div>
              </div>
            )}
            {!authData?.token && (
              <Button onClick={loginTemplate} tooltip="Login" style={Style.loginIcon}>
                <Avatar image={getImage(false, "", Images.LOGIN_ICON)} shape="circle" size="large" style={Style.avatarStyle} />
              </Button>
            )}
          </div>
        </div>
      )}

      <div id="header" className="header">
        <nav className="navbar navbar-expand-lg navbar-light text-capitalize">
          <div className="header-menu">
            <button
              ref={menuButtonRef}
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#show-menu"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse toolbar" id="show-menu">
              <ul className="navbar-nav" style={Style.navbar}>
                {articleAreaList.map((article) => {
                  return (
                    <li key={article.articleID} className="nav-item" style={Style.navItem}>
                      <button
                        ref={itemButtonRef}
                        className={`btn dropdown-toggle dropdown_no_arrow button_small_screen_align ${selectedButton === article.title ? "background-button" : ""}`}
                        style={Style.dropdownButton}
                        type="button"
                        onClick={(e) => {
                          setSelectedButton(article.title);
                          onItemButtonClick(e);
                          history.push({
                            pathname: "/articleList/" + article.articleID,
                            state: { articleID: article.articleID },
                          });
                        }}
                      >
                        {article.title}
                      </button>
                    </li>
                  );
                })}
                <li className="nav-item" style={Style.navItem}>
                  <button
                    className={`btn dropdown-toggle dropdown_no_arrow button_small_screen_align ${selectedButton === Labels.COUNSELING ? "background-button" : ""}`}
                    style={Style.dropdownButton}
                    type="button"
                    onClick={(e) => {
                      setSelectedButton(Labels.COUNSELING);
                      onItemButtonClick(e);
                      history.push({
                        pathname: "/savetovalista",
                      });
                    }}
                  >
                    {Labels.COUNSELING}
                  </button>
                </li>
                <li className="nav-item" style={Style.navItem}>
                  <button
                    className={`btn dropdown-toggle dropdown_no_arrow button_small_screen_align ${selectedButton === Labels.WALKS ? "background-button" : ""}`}
                    style={Style.dropdownButton}
                    type="button"
                    onClick={(e) => {
                      setSelectedButton(Labels.WALKS);
                      onItemButtonClick(e);
                      history.push({
                        pathname: "/eventList",
                      });
                    }}
                  >
                    {Labels.WALKS}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
