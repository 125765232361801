export const Style = {
  logo: {
    width: "50px",
    height: "50px",
    marginLeft: "40px",
    marginTop: "10px",
  },
  logoOnMobile: {
    width: "80px",
    height: "80px",
    marginTop: "20px",
  },
  gridStyle: {
    width: "100%",
  },
  avatarStyle: {
    width: "55px",
    height: "55px",
    cursor: "pointer",
  },
  rightAlign: {
    justifyContent: "right",
    display: "flex",
  },
  centerAlign: {
    textAlign: "center",
  },
  avatarStyleUser: {
    width: "48px",
    height: "48px",
    marginTop: "10px",
    cursor: "pointer",
    marginLeft: "15px",
  },
  navbar: {
    margin: "0 auto",
  },
  dropdownButton: {
    width: "10em",
    fontWeight: "bold",
  },
  dropdownItem: {
    color: "black !important",
    textTransform: "none",
  },
  loginIcon: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    backgroundClip: "border-box",
    border: "1px solid rgba(0,0,0,0)",
    backgroundColor: "white",
  },
  navItem: {
    padding: 0,
  },
  selectedButton: {
    backgroundColor: "dark blue",
    color: "white",
  },
};
