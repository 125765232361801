import CtOrganizationDto from "../../model/codetable/CtOrganizationDto";
import { imageExtenstion } from "../../components/system/Utils";

interface OrganizationLogoProps {
  organization: CtOrganizationDto;
}

export default function DisplayOrganizationLogo(props: OrganizationLogoProps) {
  const { organization } = props;
  return (
    <div style={{ textAlign: "center" }}>
      <a href={props.organization.websiteUrl} target="_blank" rel="noopener noreferrer">
        <img style={{ padding: "10px" }} src={`data:image/${imageExtenstion(organization.logo!)};base64,${organization.logo!}`} alt={props.organization.name} />
      </a>
    </div>
  );
}
