import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { axiosConfig } from "../system/Utils";
import { useEffectOnce } from "react-use";
import GridLoader from "react-spinners/GridLoader";
import Labels from "../system/Labels_sr_Latn_RS";
import Endpoint from "../system/Endpoint";

interface UseParamsType {
  code?: string;
}

export default function UnsubscribeFeedback() {
  const code = useParams<UseParamsType>()["code"];
  const [loading, setLoading] = useState(false);
  const [isUnsubscribed, setIsUnsubscribed] = useState<boolean>(false);

  const unsubscribe = () => {
    setLoading(true);
    axios
      .get(Endpoint.USER_UNSUBSCRIBE + `/${code}`, axiosConfig())
      .then(() => {
        setIsUnsubscribed(true);
      })
      .catch(() => {
        setIsUnsubscribed(false);
      })
      .finally(() => setLoading(false));
  };

  useEffectOnce(() => {
    unsubscribe();
  });

  return (
    <div className="p-grid p-flex-row p-justify-center p-align-center forms-layout unsubscribe-feedback">
      <div className="p-col-8">
        <div className="card p-align-center">
          {loading ? (
            <GridLoader size={25} color={"#2a918b"} loading={loading} />
          ) : (
            <div className="unsubscribe-feedback__message">
              {isUnsubscribed ? (
                <p className="p-mb-0">
                  <i className="pi pi-check-circle p-mr-2 success-icon"></i>
                  {Labels.MESSAGE_SUCCESS_UNSUBSCRIBE}
                </p>
              ) : (
                <p className="p-mb-0">
                  <i className="pi pi-exclamation-circle p-mr-2 error-icon"></i>
                  {Labels.MESSAGE_ERROR_UNSUBSCRIBE}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
