import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GridLoader from "react-spinners/GridLoader";
import Endpoint from "../../system/Endpoint";
import Labels from "../../system/Labels_sr_Latn_RS";
import { ARTICLE_LIST_PAGE_SIZE, axiosConfig, getImage } from "../../system/Utils";
import { Style } from "./ArticleStyle";

export default function ArticleScreen() {
  const history = useHistory();
  const { id } = useParams();
  const [articleList, setArticleList] = useState([]);
  const [page, setPage] = useState(0);
  const [loadButtonVisible, setLoadButtonVisible] = useState(true);
  const [newPage, setNewPage] = useState(true);
  const [loading, setLoading] = useState(true);

  const fetchArticleSearch = () => {
    axios
      .post(Endpoint.WEB_ARTICLE_SEARCH, { articleAreaList: [{ id: id }] }, axiosConfig({ page: page, size: ARTICLE_LIST_PAGE_SIZE }))
      .then((responseArticleList) => {
        if (responseArticleList.data.data.length !== 0) {
          setArticleList(articleList.concat(responseArticleList.data.data));
          if (responseArticleList.data.data.length < ARTICLE_LIST_PAGE_SIZE) setLoadButtonVisible(false);
        } else if (responseArticleList.data.data.length === 0) setLoadButtonVisible(false);
        setLoading(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchArticleSearch();
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, newPage]);

  useEffect(() => {
    setPage(0);
    setArticleList([]);
    setLoadButtonVisible(true);
    setNewPage(!newPage);
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getMoreArticles = () => {
    if (articleList.length < ARTICLE_LIST_PAGE_SIZE) {
      setLoadButtonVisible(false);
    } else setPage(page + 1);
  };

  return (
    <div id="home" className="container" style={{ marginTop: "100px" }}>
      <>
        <div className="row d-flex justify-content-around mt-3">
          {articleList.map((article) => {
            return (
              <div key={article.id} className="card mt-3 mb-3" style={{ width: "22rem" }}>
                <button
                  className="card mt-2 mb-2 btn-cursor"
                  style={{ borderColor: "white", cursor: "pointer" }}
                  type="button"
                  onClick={() => {
                    history.push({
                      pathname: `/article/${article.id}`,
                    });
                  }}
                >
                  {article.summaryImage ? (
                    <img src={getImage(false, article.summaryImage)} className="card-img-top" alt="..." />
                  ) : (
                    <img src={`http://img.youtube.com/vi/${article?.youtubeCode}/0.jpg`} className="card-img-top" alt="..." />
                  )}
                  <div className="card-body">
                    <h5 className="card-title text-left" style={Style.heading}>
                      {article.title}
                    </h5>
                    <p className="text-left">{article.summary}</p>
                  </div>
                </button>
              </div>
            );
          })}
        </div>
        <GridLoader color={"#2a918b"} loading={loading} css={Style.spinnerStyle} size={50} />
        {!loading && articleList.length !== 0 && (
          <div style={Style.prevNextButtonsContainer}>
            <button
              className="btn btn-primary background-color-curious-blue"
              type="button"
              disabled={!loadButtonVisible}
              onClick={() => {
                getMoreArticles();
              }}
            >
              {Labels.LOAD_MORE}
            </button>
          </div>
        )}
      </>
    </div>
  );
}
