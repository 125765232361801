import axios from "axios";
import { useState } from "react";
import { useEffectOnce } from "react-use";
import Endpoint from "../../components/system/Endpoint";
import CtOrganizationDto from "../../model/codetable/CtOrganizationDto";
import DisplayOrganizationLogo from "./DisplayOrganizationLogo";

export default function DisplaySponsorList() {
  const [sponzorOrganizationList, setSponzorOrganizationList] = useState([]);

  useEffectOnce(() => {
    const requestSponsorOrganizationList = axios.get(Endpoint.SPONSOR_ORGANIZATION_LIST);

    axios
      .all([requestSponsorOrganizationList])
      .then(
        axios.spread((responseSponsorOrganizationList: any) => {
          setSponzorOrganizationList(responseSponsorOrganizationList.data);
        })
      )
      .catch((e) => {});
  });

  return (
    <div className="p-grid p-justify-around p-fluid">
      {sponzorOrganizationList.map((organization: CtOrganizationDto) => {
        return (
          <div key={organization.id} className="p-xl-1 p-lg-1 p-md-3 p-sm-6 p-col-6 p-col-align-center">
            <DisplayOrganizationLogo key={organization.id} organization={organization} />
          </div>
        );
      })}
    </div>
  );
}
