import { useEffect } from "react";
import Endpoint from "../system/Endpoint";
import Images from "../system/Images";
import { Style } from "./MainStyle";

export default function Savetovalista() {
  const { tableau } = window;

  useEffect(() => {
    var containerDivSavetovaliste = document.getElementById("vizContainerSavetovaliste"),
      url = Endpoint.SAVETOVALISTA,
      options = {
        hideTabs: true,
        onFirstInteractive: function () {},
      };

    new tableau.Viz(containerDivSavetovaliste, url, options);
  }, [tableau]);

  return (
    <div style={{ height: "100%" }}>
      <body>
        <div id="header"></div>
        <div id="home" className="slider">
          <img className="d-block w-100" src={Images.SAVETOVALISTA_U_BEOGRADU} alt="slider_img" />
        </div>
        <div id="home" className="container" style={{ marginBottom: "40px" }}>
          <h4 className="mt-5 mb-5" style={{ color: "rgb(42, 145, 139)", textAlign: "center" }}>
            Na ovoj stranici možete da pronađete informacije o tome gde na teritoriji Grada Beograda postoji dodatno organizovana podrška za savetovanje o dijabetesu
          </h4>
          <div className="row d-flex justify-content-around mt-3">
            <a href="http://www.dzzemun.org.rs/" target="_blank" rel="noreferrer">
              <div className="card" style={Style.cardContainer}>
                <img src="/imgs/savetovalistaUBeogradu/dom-zdravlja-zemun.jpg" className="card-img-top" alt="..." style={{ height: "60%" }} />
                <div className="card-body" style={Style.centerText}>
                  <h5 className="card-title" style={Style.heading}>
                    Dom zdravlja Zemun
                  </h5>
                  <p style={Style.textStyle}>Rada Končara 46</p>
                  <p style={Style.textStyle}>Linije gradskog prevoza: 15, 18, 45, 78, 612</p>
                  <p style={Style.textStyle}>Radno vreme: svakog radnog dana od 7h do 20h</p>
                </div>
              </div>
            </a>
            <a href="https://www.dzcukarica.rs/" target="_blank" rel="noreferrer">
              <div className="card" style={Style.cardContainer}>
                <img src="/imgs/savetovalistaUBeogradu/dom-zdravlja-cukarica.jpg" className="card-img-top" alt="..." style={{ height: "60%" }} />
                <div className="card-body" style={Style.centerText}>
                  <h5 className="card-title" style={Style.heading}>
                    Dom zdravlja Čukarica
                  </h5>
                  <p style={Style.textStyle}>Požeška 82</p>
                  <p style={Style.textStyle}>Linije gradskog prevoza: 12, 13, 23, 37, 49, 50, 52, 58, 85, 88</p>
                  <p style={Style.textStyle}>Radno vreme: svakog dana od 7h do 20h.</p>
                </div>
              </div>
            </a>
            <a href="https://dzlazarevac.rs/" target="_blank" rel="noreferrer">
              <div className="card" style={Style.cardContainer}>
                <img src="/imgs/savetovalistaUBeogradu/dom-zdravlja-lazarevac.jpg" className="card-img-top" alt="..." style={{ height: "60%" }} />
                <div className="card-body" style={Style.centerText}>
                  <h5 className="card-title" style={Style.heading}>
                    Dom zdravlja Lazarevac
                  </h5>
                  <p style={{ color: "black" }}>dr Đorđa Kovačevića 27</p>
                  <p style={Style.textStyle}>Linije međugradskog prevoza: 581</p>
                  <p style={Style.textStyle}>Radno vreme: ponedeljkom i petkom od 7h do 14h i sredom od 11h do 14h</p>
                </div>
              </div>
            </a>
          </div>

          <div className="row d-flex justify-content-around mt-3">
            <a href="http://www.dzvozdovac.rs/" target="_blank" rel="noreferrer">
              <div className="card" style={Style.cardContainer}>
                <img src="/imgs/savetovalistaUBeogradu/dom-zdravlja-vozdovac.jpg" className="card-img-top" alt="..." style={{ height: "60%" }} />
                <div className="card-body" style={Style.centerText}>
                  <h5 className="card-title" style={Style.heading}>
                    Dom zdravlja Voždovac
                  </h5>
                  <p style={Style.textStyle}>Krivolačka 4-6</p>
                  <p style={Style.textStyle}>Linije gradskog prevoza: 17, 30, 31</p>
                  <p style={Style.textStyle}>Radno vreme: utorkom, sredom i četvrtkom od 8h do 14h.</p>
                </div>
              </div>
            </a>
            <a href="http://dzstarigrad.org/dom-zdravlja/" target="_blank" rel="noreferrer">
              <div className="card" style={Style.cardContainer}>
                <img src="/imgs/savetovalistaUBeogradu/dom-zdravlja-stari-grad.jpg" className="card-img-top" alt="..." style={{ height: "60%" }} />
                <div className="card-body" style={Style.centerText}>
                  <h5 className="card-title" style={Style.heading}>
                    Dom zdravlja Stari grad
                  </h5>
                  <p style={Style.textStyle}>Simina 27</p>
                  <p style={Style.textStyle}>Linije gradskog prevoza: 2, 5, 10, 16, 24, 26, 31, 37, 41, 44, 58, 75, 77, 79</p>
                  <p style={Style.textStyle}>Radno vreme: od ponedeljka do petka od 7h do 14:30h prva smena i od 12:30h do 20h druga smena</p>
                </div>
              </div>
            </a>
            <a href="http://www.dznbgd.com/" target="_blank" rel="noreferrer">
              <div className="card" style={Style.cardContainer}>
                <img src="/imgs/savetovalistaUBeogradu/dom-zdravlja-novi-beograd.jpg" className="card-img-top" alt="..." style={{ height: "60%" }} />
                <div className="card-body" style={Style.centerText}>
                  <h5 className="card-title" style={Style.heading}>
                    Dom zdravlja Novi Beograd
                  </h5>
                  <p style={Style.textStyle}>Nehruova 53</p>
                  <p style={Style.textStyle}>Linije gradskog prevoza: 7, 9, 67, 73, 89, 94, 95, 610, 708</p>
                  <p style={Style.textStyle}>Radno vreme: svakog radnog dana od 11:30h do 13:30h</p>
                </div>
              </div>
            </a>
          </div>

          <div className="row d-flex justify-content-around mt-3">
            <a href="http://dzsopot.rs/" target="_blank" rel="noreferrer">
              <div className="card" style={Style.cardContainer}>
                <img src="/imgs/savetovalistaUBeogradu/dom-zdravlja-sopot.jpg" className="card-img-top" alt="..." style={{ height: "60%" }} />
                <div className="card-body" style={Style.centerText}>
                  <h5 className="card-title" style={Style.heading}>
                    Dom zdravlja Sopot
                  </h5>
                  <p style={Style.textStyle}>Jelice Milovanović 12</p>
                  <p style={Style.textStyle}>Linije međugradskog prevoza: 4410, 4428, 465</p>
                  <p style={Style.textStyle}>Radno vreme: svakog radnog dana od 13h do 14h.</p>
                </div>
              </div>
            </a>
            <a href="https://www.dzsvenac.rs/" target="_blank" rel="noreferrer">
              <div className="card" style={Style.cardContainer}>
                <img src="/imgs/savetovalistaUBeogradu/dom-zdravlja-savski-venac.jpg" className="card-img-top" alt="..." style={{ height: "60%" }} />
                <div className="card-body" style={Style.centerText}>
                  <h5 className="card-title" style={Style.heading}>
                    Dom zdravlja Savski venac
                  </h5>
                  <p style={Style.textStyle}>Pasterova 1</p>
                  <p style={Style.textStyle}>Linije gradskog prevoza: 23, 31, 37, 41, 42, 49, 52, 53, 58, 78, 94, 601, A1</p>
                  <p style={Style.textStyle}>Radno vreme: svakog radnog dana od 12:30h do 14:30h.</p>
                </div>
              </div>
            </a>
            <a href="https://www.dzgrocka.rs/" target="_blank" rel="noreferrer">
              <div className="card" style={Style.cardContainer}>
                <img src="/imgs/savetovalistaUBeogradu/dom-zdravlja-grocka.jpg" className="card-img-top" alt="..." style={{ height: "60%" }} />
                <div className="card-body" style={Style.centerText}>
                  <h5 className="card-title" style={Style.heading}>
                    Dom zdravlja Grocka
                  </h5>
                  <p style={Style.textStyle}>Srpsko – grčkog prijateljstva 17</p>
                  <p style={Style.textStyle}>Linije međugradskog prevoza:</p>
                  <p style={Style.textStyle}>Radno vreme: sredom od 7 do 20h</p>
                </div>
              </div>
            </a>
          </div>

          <div id="spisak-gradova-ID" className="tableau-map-container mt-5">
            <div id="vizContainerSavetovaliste" className="tableau-map-iframe-container"></div>
          </div>
        </div>
      </body>
    </div>
  );
}
