export const Style = {
  heading: {
    color: "rgb(42, 145, 139)",
  },
  justifyText: {
    textAlign: "justify",
  },
  cardContainer: {
    width: "22rem",
    height: "450px",
  },
  centerText: {
    textAlign: "center",
    padding: "0.5rem",
  },
  textStyle: {
    color: "black",
    marginBottom: "0.25rem",
    fontSize: "14px",
  },
};
