import { Toast } from "primereact/toast";
import { useContext } from "react";
import { AppContext } from "./Store";

export default function Message() {
  const { setMessages } = useContext(AppContext);

  return (
    <div className="layout-message">
      <Toast
        ref={(el) => {
          setMessages(el);
        }}
      />
    </div>
  );
}
