export const Style = {
  text: {
    color: "rgb(42, 145, 139)",
  },
  textBlack: {
    color: "black",
  },
  partners: {
    textAlign: "center",
    margin: "auto",
  },
  cursorPointer: {
    cursor: "pointer",
    width: "100%",
  },
  nordicCard: {
    cursor: "pointer",
    width: "100%",
    backgroundColor:"#5eabe6",
  },
  sponsorTitle: {
    color: "white",
    fontWeight: "bold",
    fontSize: 25,
    margin: "auto",
  },
  spinnerStyle: {
    display: "block",
    margin: "100px auto",
    borderColor: "#2a918b",
  },
};
