export const Style = {
  heading: {
    color: "rgb(42, 145, 139)",
    fontSize: 25,
  },
  prevNextButtonsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginBottom: "20px",
    marginTop: "20px",
  },
  socialButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  spinnerStyle: {
    display: "block",
    margin: "300px auto",
    borderColor: "#2a918b",
  },
  fontColor: {
    color: "rgb(42, 145, 139)",
  },
};
