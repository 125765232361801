import axios from "axios";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Password } from "primereact/password";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Endpoint from "../system/Endpoint";
import Labels from "../system/Labels_sr_Latn_RS";
import MessageType from "../system/MessageType";
import { AppContext } from "../system/Store";
import { handleAxiosCallError, handleAxiosCallSuccess } from "../system/Utils";
import { useLocation } from "react-router-dom";

const axiosConfig = {
  headers: {
    "Content-Type": "application/json;",
  },
};

export default function Login() {
  const { updateAuthData, showMessage } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();
  const dataFromRegistration: any = location.state;
  const [userName, setUserName] = useState(dataFromRegistration ? dataFromRegistration.email : "");
  const [password, setPassword] = useState(dataFromRegistration ? dataFromRegistration.password : "");
  const [rememberPass, setRememberPass] = useState(false);

  const postLogin = (e: any) => {
    e.preventDefault();
    const postData = {
      username: userName,
      password: password,
    };

    axios
      .post(Endpoint.AUTHENTICATE, postData, axiosConfig)
      .then((result) => {
        const { jwt, user } = result.data.data;
        updateAuthData(
          JSON.stringify({
            token: jwt,
            currentUser: user,
          })
        );
        history.push("/");
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  const resetPassword = () => {
    if (!userName) {
      showMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_RESET_PASSWORD, Labels.MESSAGES_EMAIL_FIELD);
      return;
    }

    axios
      .post(`${Endpoint.RESET_PASSWORD}/${userName}`, {}, axiosConfig)
      .then((response) => {
        handleAxiosCallSuccess(showMessage, response);
      })
      .catch((e) => {
        handleAxiosCallError(showMessage, e);
      });
  };

  return (
    <div className="p-col-12 panel-registration">
      <div className="p-grid p-dir-row p-nogutter p-justify-center p-align-center">
        <div className="p-grid p-fluid forms-layout">
          <div className="p-col-12 p-lg-6">
            <div className="p-col-12 panel-registration">
              <div className="p-grid p-dir-row p-nogutter p-justify-center p-align-center div-size">
                <Panel header={Labels.TITLE_LOGIN}>
                  <form onSubmit={postLogin}>
                    <div className="p-col-12">
                      <div className="p-col-12 form-input">
                        <InputText
                          className="p-col-12"
                          value={userName}
                          onChange={(e: any) => {
                            setUserName(e.target.value);
                          }}
                          placeholder={Labels.PLACEHOLDER_EMAIL_REQUIRED}
                          autoComplete="username"
                        />
                      </div>
                      <div className="p-col-12 form-input">
                        <Password
                          className="p-col-12"
                          feedback={false}
                          value={password}
                          onChange={(e: any) => {
                            setPassword(e.target.value);
                          }}
                          placeholder={Labels.PLACEHOLDER_PASSWORD}
                          autoComplete="current-password"
                        />
                      </div>
                      <div className="p-col-12">
                        <Checkbox inputId="checkBoxRememberPass" onChange={() => setRememberPass(!rememberPass)} checked={rememberPass} />
                        <label htmlFor="checkBoxRememberPass" className="p-checkbox-label">
                          {Labels.CHECKBOX_REMEMBER_PASSWORD}
                        </label>
                      </div>
                      <div className="p-col-12 form-button">
                        <Button label={Labels.BUTTON_LOGIN} className="p-col-12" onClick={postLogin} />
                      </div>
                      <div className="p-col-12">
                        <a href="#." onClick={resetPassword} className="password-link">
                          {Labels.FORGOTTEN_PASSWORD}
                        </a>
                      </div>
                      <div className="p-col-12">
                        {Labels.DONT_HAVE_ACCOUNT}
                        <a href="/registration" className="registration-link">
                          {Labels.REGISTRATION}
                        </a>
                      </div>
                    </div>
                  </form>
                </Panel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
