import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Message from "./components/system/Message";
import Router from "./components/system/Router";
import Store from "./components/system/Store";

export default function App() {
  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Store>
        <Header />
        <div style={{ flex: 1 }}>
          <Message />
          <Router />
        </div>
        <Footer />
      </Store>
    </div>
  );
}
