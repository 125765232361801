import axios from "axios";
import moment from "moment";
import { Card } from "primereact/card";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import GridLoader from "react-spinners/GridLoader";
import EnumEventSport from "../../model/event/EnumEventSport";
import EventReadDto from "../../model/event/EventReadDto";
import Endpoint from "../system/Endpoint";
import EnumEventStatus from "../system/EnumEventStatus";
import Images from "../system/Images";
import Labels from "../system/Labels_sr_Latn_RS";
import { axiosConfig, DATE_TIME_FORMAT, EVENTS_LIST_PAGE_SIZE, getImage, numberRepresentation } from "../system/Utils";

export default function EventListScreen() {
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [eventList, setEventList] = useState<Array<EventReadDto>>([]);
  const [loadButtonVisible, setLoadButtonVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const fetchEventsSearch = () => {
    axios
      .get(Endpoint.EVENTS, axiosConfig({ page: page, size: EVENTS_LIST_PAGE_SIZE }))
      .then((responseEventList) => {
        if (responseEventList.data.data.length !== 0) {
          setEventList(eventList.concat(responseEventList.data.data));
          if (responseEventList.data.data.length < EVENTS_LIST_PAGE_SIZE) setLoadButtonVisible(false);
        } else if (responseEventList.data.data.length === 0) setLoadButtonVisible(false);
        setLoading(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchEventsSearch();
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getMoreEvents = () => {
    if (eventList.length < EVENTS_LIST_PAGE_SIZE) {
      setLoadButtonVisible(false);
    } else setPage(page + 1);
  };

  return (
    <div id="home" className="container" style={{ marginTop: "100px" }}>
      <>
        <GridLoader color={"#2a918b"} loading={loading} size={50} css={"display: block; margin: 300px auto; borderColor: #2a918b"} />

        {eventList && eventList.length ? (
          <div className="row d-flex justify-content-around mt-3">
            {eventList.map((event: EventReadDto) => {
              return (
                <div key={event.id} className="layout-event">
                  <button
                    className={event.eventSport.code === EnumEventSport.NORDIC_WALKING ? "card mt-2 mb-2 btn-cursor nordic-card" : "card mt-2 mb-2 btn-cursor"}
                    // style={event.eventSport.code === EnumEventSport.NORDIC_WALKING ? Style.nordicCard : Style.cursorPointer}
                    onClick={() => {
                      history.push({
                        pathname: `/event/${event.id}`,
                      });
                    }}
                  >
                    <div className="card mt-3 mb-3" style={event.eventSport.code === EnumEventSport.NORDIC_WALKING ? { width: "22rem", backgroundColor: "#5eabe6" } : { width: "22rem" }}>
                      <div className="header-card-title">
                        <label className={event.eventSport.code === EnumEventSport.NORDIC_WALKING ? "card-title-nordic" : "card-title"}> {event?.title} </label>
                      </div>
                      <img src={getImage(false, event.summaryImage, Images.EVENT_DEFAULT)} className="card-img-top card-img-event" alt="..." />
                      <div className="card-body">
                        <div className="p-grid">
                          <div className="p-col-6">
                            <Card className={isTabletOrMobile ? "small-card" : "card-content-font-size"}>
                              <p className="card-title">{Labels.EVENT_TIME}</p>
                              <p className="card-text">{moment(event?.startTime).format(DATE_TIME_FORMAT)}</p>
                              <hr />
                              <p className="card-title">{Labels.EVENT_GROUP}</p>
                              <p className="card-text">{event?.eventDifficulty?.name}</p>
                            </Card>
                          </div>
                          <div className="p-col-6">
                            <Card className={isTabletOrMobile ? "small-card" : "card-content-font-size"}>
                              <p className="card-title">{Labels.EVENT_STATUS}</p>
                              <p className={event?.eventStatus?.code === EnumEventStatus.CANCELED ? "card-orange-text" : "card-text"}>{event?.eventStatus?.name}</p>
                              <hr />
                              <p className="card-title">{Labels.EVENT_DISTANCE}</p>
                              {event?.totalDistanceMeters && (
                                <p className="card-text">
                                  {numberRepresentation(event?.totalDistanceMeters)} {Labels.METER}
                                </p>
                              )}
                            </Card>
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="p-grid">
            {!loading && (
              <div className="p-col-12">
                <Card>
                  <div>{Labels.EVENT_LIST_NO_DATA}</div>
                </Card>
              </div>
            )}
          </div>
        )}

        {!loading && eventList && eventList.length > 0 && (
          <div className="layout-event">
            <div className="button-area">
              <button
                className="btn btn-primary background-color-curious-blue"
                type="button"
                disabled={!loadButtonVisible}
                onClick={() => {
                  getMoreEvents();
                }}
              >
                {Labels.LOAD_MORE}
              </button>
            </div>
          </div>
        )}
      </>
    </div>
  );
}
