import axios from "axios";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Password } from "primereact/password";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Endpoint from "../system/Endpoint";
import Labels from "../system/Labels_sr_Latn_RS";
import MessageType from "../system/MessageType";
import { AppContext } from "../system/Store";
import { handleAxiosCallError } from "../system/Utils";
import GridLoader from "react-spinners/GridLoader";

const axiosConfig = {
  headers: {
    "Content-Type": "application/json;",
  },
};

export default function Registration() {
  const { showMessage } = useContext(AppContext);

  const history = useHistory();

  const [chosenName, setChosenName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(false);
  const [isDisabledRegistrationButton, setIsDisabledRegistrationButton] = useState(false);

  const blockUI = (isBlocked: boolean) => {
    setIsGridLoading(isBlocked);
    setIsDisabledRegistrationButton(isBlocked);
  };

  const postRegistration = (e: any) => {
    const postData = {
      chosenName: chosenName,
      email: email,
      password: password,
      passwordConfirm: confirmPassword,
      privacyPolicyAccepted: privacyPolicyAccepted,
      receiveNotification: true,
      firebaseDeviceID: null,
    };

    axios
      .post(Endpoint.REGISTER, postData, axiosConfig)
      .then((registrationResponse: any) => {
        blockUI(false);
        showMessage(MessageType.SUCCESS, Labels.MESSAGE_SUCCESS_REGISTRATION, "");
        history.push({ pathname: "/login", state: { password: password, email: email } });
      })
      .catch((registrationError: any) => {
        handleAxiosCallError(showMessage, registrationError);
        blockUI(false);
      });
  };

  return (
    <div className="p-col-12 panel-registration">
      <div className="p-grid p-dir-row p-nogutter p-justify-center p-align-center">
        <div className="p-grid p-fluid layout-registration">
          <div className="p-col-12 p-lg-6">
            <div className="p-col-12 panel-registration">
              <div className="p-grid p-dir-row p-nogutter p-justify-center p-align-center div-size">
                {!isGridLoading && (
                  <Panel header={Labels.TITLE_REGISTRATION}>
                    <div className="p-col-12">
                      <div className="p-col-12 login-input">
                        <span className="p-float-label">
                          <InputText
                            id="chosenName"
                            className="p-col-12"
                            value={chosenName}
                            onChange={(e: any) => {
                              setChosenName(e.target.value);
                            }}
                          />
                          <label htmlFor="email">{Labels.CHOSEN_NAME_REQUIRED}</label>
                        </span>
                      </div>
                      <div className="p-col-12 login-input">
                        <span className="p-float-label">
                          <InputText
                            id="email"
                            className="p-col-12"
                            value={email}
                            onChange={(e: any) => {
                              setEmail(e.target.value);
                            }}
                          />
                          <label htmlFor="email">{Labels.EMAIL_REQUIRED}</label>
                        </span>
                      </div>
                      <div className="p-col-12 login-input">
                        <span className="p-float-label">
                          <Password
                            id="password"
                            className="p-col-12"
                            feedback={false}
                            value={password}
                            onChange={(e: any) => {
                              setPassword(e.target.value);
                            }}
                            toggleMask
                          />
                          <label htmlFor="password">{Labels.PASSWORD_REQUIRED}</label>
                        </span>
                      </div>
                      <div className="p-col-12 login-input">
                        <span className="p-float-label">
                          <Password
                            id="confirmPassword"
                            className="p-col-12"
                            feedback={false}
                            value={confirmPassword}
                            onChange={(e: any) => {
                              setConfirmPassword(e.target.value);
                            }}
                            toggleMask
                          />
                          <label htmlFor="confirmPassword">{Labels.CONFIRM_PASSWORD_REQUIRED}</label>
                        </span>
                      </div>
                      <div className="p-col-12">
                        <a href={Endpoint.PRIVACY_POLICY} className="policy-link" target="_blank" rel="noreferrer">
                          {Labels.PRIVACY_POLICY}
                        </a>
                      </div>
                      <div className="p-col-12">
                        <Checkbox inputId="privacyPolicyAccepted" onChange={() => setPrivacyPolicyAccepted(!privacyPolicyAccepted)} checked={privacyPolicyAccepted} />
                        <label htmlFor="privacyPolicyAccepted" className="p-checkbox-label">
                          {Labels.PRIVACY_POLICY_ACCEPTED}
                        </label>
                      </div>
                      <div className="p-col-12 login-button">
                        <Button
                          disabled={isDisabledRegistrationButton}
                          label={Labels.BUTTON_REGISTRATION}
                          className="p-col-12"
                          onClick={(e) => {
                            blockUI(true);
                            postRegistration(e);
                          }}
                        />
                      </div>
                    </div>
                  </Panel>
                )}
                <GridLoader color={"#2a918b"} loading={isGridLoading} size={50} css={"display: block; margin: 300px auto; borderColor: #2a918b"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
