import { createContext, useState } from "react";
import AuthData from "../../model/AuthData";
import UserReadDto from "../../model/UserReadDto";

export const AppContext = createContext({} as AppContextProps);

const AUTH_NAME = "auth-opk-website";

interface AppContextProps {
  authData: AuthData;
  updateAuthData: (data: string) => void;
  deleteAuthData: () => void;
  showMessage: (summery: string, detail: string, severity: string) => void;
  setMessages: (messages: any) => void;
}

const Store = (props: any) => {
  const [authData, setAuthData] = useState(localStorage.getItem(AUTH_NAME) || "");
  const [messages, setMessages] = useState<any>("");

  const clear = async (messages: any) => {
    messages.clear();
  };

  const showMessage = async (severity: string, summary: string, detail: string) => {
    await clear(messages);
    messages.show({
      severity: severity,
      summary: summary,
      detail: detail,
      closable: true,
      life: 8000,
    });
  };

  const updateAuthData = (data: string) => {
    localStorage.setItem(AUTH_NAME, data);
    setAuthData(data);
  };

  const deleteAuthData = () => {
    setAuthData("");
    localStorage.removeItem(AUTH_NAME);
  };

  return (
    <AppContext.Provider
      value={{
        authData: authData !== "" ? (JSON.parse(authData) as AuthData) : new AuthData("", new UserReadDto(0, "", "", "", false, 0, 0, "")),
        updateAuthData,
        deleteAuthData,
        showMessage,
        setMessages,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default Store;
