import Endpoint from "../system/Endpoint";
import Images from "../system/Images";
import Labels from "../system/Labels_sr_Latn_RS";

export default function Footer() {
  return (
    <footer>
      <div className="container layout-footer">
        <div className="row d-flex d-flex justify-content-around align-items-center opk-container">
          <div className="col-lg-3 col-md-7 col-12">
            <div className="d-flex justify-content-around align-items-center">
              <div>
                <a href="/">
                  <img src={Images.LOGO_ON_DARK} alt="footerLogo" className="opk-logo" />
                </a>
                <a href={Endpoint.PRIVACY_POLICY} target="_blank" rel="noreferrer" className="opk-privacy-policy">
                  <label className="opk-cursor-pointer">{Labels.LABEL_PRIVACY_POLICY}</label>
                </a>
              </div>
              <a href={Endpoint.TWITTER_CITIESDIABETES} className="opk-logo" target="_blank" rel="noreferrer">
                <img src={Images.TWITTER} alt="twitterLogo" className="opk-twitter-logo" />
              </a>
              <a href={Endpoint.OPK_FACEBOOK} className="opk-facebook-logo" target="_blank" rel="noreferrer">
                <img src={Images.FACEBOOK} alt="facebookLogo" className="opk-facebook-logo-image" />
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-7 col-12">
            <div>
              <a href={Endpoint.OPK_APPLE_STORE} className="opk-margin-right-10px" target="_blank" rel="noreferrer">
                <img src={Images.APP_STORE} alt="App Store" className="opk-store-image" />
              </a>
              <a href={Endpoint.OPK_GOOGLE_STORE} target="_blank" rel="noreferrer">
                <img src={Images.GOOGLE_PLAY} alt="Play Store" className="opk-store-image" />
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-7 col-12">
            <div className="item">
              <h4 className="text-uppercase">{Labels.CONTACT}</h4>
              <ul>
                <li>
                  <a href={Endpoint.CONTACT_MAIL}>{Labels.OPK_MAIL}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright text-center">
        <p>
          {`${Labels.DELIVERED_BY} `}
          <a href={Endpoint.HELIANT} target="_blank" rel="noreferrer">
            {Labels.HELIANT}
          </a>
        </p>
      </div>
    </footer>
  );
}
