const Images = {
  NO_PROFILE: require("./../../images/no_profile.png").default,
  EVENT_DEFAULT: require("../../images/event_default.jpg").default,
  EVENT_HEADER_DEFAULT: require("../../images/event_header.jpg").default,
  LOGIN_ICON: require("../../images/login_icon.png").default,
  TALK_ABOUT_DIABETES: require("../../images/talk_about_diabetes.png").default,
  LEFT: require("../../images/left.png").default,
  RIGHT: require("../../images/right.png").default,
  GOOGLE_PLAY: require("../../images/google_play.png").default,
  APP_STORE: require("../../images/app_store.png").default,
  LOGO_ON_DARK: require("../../images/logo_on_dark.png").default,
  LOGO_ON_WHITE: require("../../images/logo_on_white.png").default,
  TWITTER: require("../../images/twitter.png").default,
  FACEBOOK: require("../../images/facebook.png").default,
  GRADOVI_MENJAJU_DIJABETES: require("../../images/partner_gradovi_menjaju_dijabetes.png").default,
  SAVETOVALISTA_U_BEOGRADU: require("../../images/savetovalista_u_beogradu.jpg").default,
};

export default Images;
