import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Labels from "../system/Labels_sr_Latn_RS";
import { useContext, useState } from "react";
import { axiosConfig, handleAxiosCallError, validateEmail } from "../system/Utils";
import axios, { AxiosError } from "axios";
import Endpoint from "../system/Endpoint";
import MessageType from "../system/MessageType";
import { AppContext } from "../system/Store";
import GridLoader from "react-spinners/GridLoader";

export default function Unsubscribe() {
  const { showMessage } = useContext(AppContext);
  const [email, setEmail] = useState<string>("");
  const [isGridLoading, setIsGridLoading] = useState<boolean>(false);
  const [isDisabledUnsubscribeButton, setIsDisabledUnsubscribeButton] = useState<boolean>(false);

  const blockUI = (isBlocked: boolean) => {
    setIsGridLoading(isBlocked);
    setIsDisabledUnsubscribeButton(isBlocked);
  };

  const unsubscribe = () => {
    if (!validateEmail(email)) {
      showMessage(MessageType.ERROR, Labels.MESSAGE_UNSUBSCRIBE_EMAIL_WRONG_FORMAT, "");
      return;
    }
    blockUI(true);
    axios
      .put(Endpoint.USER_UNSUBSCRIBE, { email }, axiosConfig())
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.MESSAGE_SUCCESS_UNSUBSCRIBE_REQUEST, "");
        blockUI(false);
        setEmail("");
      })
      .catch((unsubscribeError: AxiosError) => {
        handleAxiosCallError(showMessage, unsubscribeError);
        blockUI(false);
      });
  };

  return (
    <div className="p-grid p-nogutter p-justify-center">
      <div className="forms-layout">
        <div className="margin-top-30 panel-registration panel-unsubscribe">
          <div className="p-grid p-nogutter p-justify-center">
            <Panel header={Labels.TITLE_MAIL_LIST} className="panel-unsubscribe__panel">
              <>
                <div className="p-col-12 form-input">
                  <InputText className="p-col-12" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={Labels.EMAIL_REQUIRED} />
                </div>
                <div className="p-col-12 form-button">
                  <Button label={Labels.DELETE_MAIL_LIST} className="p-col-12" onClick={() => unsubscribe()} disabled={isDisabledUnsubscribeButton} />
                </div>
              </>
            </Panel>
          </div>
          <div className="p-grid p-dir-row p-nogutter p-justify-center p-align-center div-size">
            <GridLoader color={"#2a918b"} loading={isGridLoading} size={15} css={"display: block; margin: 10px auto; borderColor: #2a918b"} />
          </div>
        </div>
      </div>
    </div>
  );
}
